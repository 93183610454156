import { DestroyRef, Injectable, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "@app/app/environments/environment";
import { selectAccount } from "@bb/states/selectors";
import { Store } from "@ngrx/store";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { distinctUntilChanged, filter, map, switchMap, tap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class SegmentService {
    private readonly store = inject(Store);
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);

    readonly analytics = AnalyticsBrowser.load({ writeKey: environment.segmentWriteKey });

    init() {
        this.store
            .select(selectAccount)
            .pipe(
                tap(account => {
                    this.analytics.identify(account.Id, {
                        name: account.FullName,
                        email: account.MailAddress,
                        child: !account.IsMasterUser,
                        parentuser: account.MasterUserId,
                        instanceid: account.InstanceId,
                    });

                    this.analytics.group(account.MasterUserId);
                }),
                switchMap(() =>
                    this.router.events.pipe(
                        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
                        map(e => e.url),
                        distinctUntilChanged(),
                    ),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(() => this.analytics.page());
    }
}
