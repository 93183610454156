import { enableProdMode, NgModuleRef } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { ResourceActionReturnType, ResourceGlobalConfig } from "@ngx-resource/core";
import { AgCharts } from "ag-charts-enterprise";
import { AppModule } from "./app.module";
import { environment } from "./environments/environment";

if (environment.production) {
    enableProdMode();
}

AgCharts.setLicenseKey(environment.agChartsEnterpriseLicense);

ResourceGlobalConfig.returnAs = ResourceActionReturnType.Promise;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).loadDone = new Promise(resolve => {
    window.onload = resolve;
});
window.localStorage.setItem("dx-theme", "dx-billbee");

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((_: NgModuleRef<AppModule>) => {});
