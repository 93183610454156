import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { SegmentService } from "@app/app/shared/service/segment.service";
import { merge, of } from "rxjs";
import { delay, startWith, switchMap } from "rxjs/operators";
import { OnboardingAssistantStep } from "../../model/step";
import { ONBOARDING_ASSISTANT_STEPS } from "../../steps/steps.token";
import { OnboardingAssistantComponentStore } from "./onboarding-assistant.store";

@Component({
    selector: "bb-onboarding-assistant",
    templateUrl: "./onboarding-assistant-dialog.component.html",
    styleUrls: ["./onboarding-assistant-dialog.component.scss"],
    providers: [OnboardingAssistantComponentStore],
    animations: [
        trigger("openClose", [
            state("open", style({ opacity: 1 })),
            state("closed", style({ opacity: 0, transform: "translateY(-20px)" })),
            transition("open => closed", [animate("0.3s ease-in-out")]),
            transition("closed => open", [animate("0.3s ease-in-out")]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingAssistantDialogComponent {
    private readonly onboardingStore = inject(OnboardingAssistantComponentStore);
    private readonly ref = inject(MatDialogRef);
    private readonly segment = inject(SegmentService);
    readonly steps: OnboardingAssistantStep[] = inject(ONBOARDING_ASSISTANT_STEPS);

    public readonly activeStep$ = this.onboardingStore.activeStepIndex$;
    public readonly taskComponent$ = this.onboardingStore.taskComponent$.pipe(delay(300));
    public readonly hasBackButton$ = this.onboardingStore.hasBackButton$;
    public readonly progress$ = this.onboardingStore.progress$;

    public readonly openClose$ = this.onboardingStore.taskComponent$.pipe(
        startWith("open"),
        switchMap(() => merge(of("closed"), of("open").pipe(delay(300)))),
    );

    onClickPrev() {
        this.onboardingStore.previousTask();
    }

    closeAssistant() {
        this.segment.analytics.track("OnboardingAssistentSkipped");
        this.ref.close();
    }
}
